import { CLEAR_DISCOUNT, GET_DISCOUNT_CODE_CONST } from "../../constants/discount/discount";


const initialState = {
  discountDetails: null
}

export default function discountReducer(state = initialState, action) {

  switch (action.type) {

    case GET_DISCOUNT_CODE_CONST:
      return {
        ...state,
        discountDetails: action.payload,
      };

      case CLEAR_DISCOUNT:
        return {
          ...state,
          discountDetails: null,
        };

    default:
      return state;
  }



}