import React, { useEffect, useState } from "react";
import "./feed-back.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/close_icon.svg";
import StepButton from "../../common/StepButton";
import Modal from "react-modal";
import FeedBackThanks from "./FeedBackThanks";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import FeedBackModelIcon from "../../../assets/images/icons/facebook.svg";
import useWindowDimensions from "../../../utils/useWindowDimensions";



const FeedBackModal = ({ isOpen, setIsOpen, bookingId, isThankOpen, setIsThankOpen, BusinessName }) => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation()
  const data = location.state
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [symbol, setSymbol] = useState(["😡", "😔", "😐", "😊", "🤩"]);
  const ratings = ["1.0", "2.0", "3.0", "4.0", "5.0"]; // Corresponding ratings
  const [feedbackText, setFeedBackText] = useState("")
  
  console.log(feedbackText, 'feedbackText')

  const { screenWidth } = useWindowDimensions();
  const handleShowTextArea = (emoji) => {
    setSelectedEmoji(emoji);
    setIsVisible(true);
  };

  const handleGenerateBookingReviewContentDataFunc = () => {
    console.log("handleGenerateBookingReviewContentDataFunc");
  }

  const handleFeedBack = () => {

    if (getSelectedRating() == null) {
      toast.error("Please select rating")
      return
    }
  }

  const getSelectedRating = () => {
    if (selectedEmoji) {
      const index = symbol.indexOf(selectedEmoji);
      if (index !== -1) {
        return ratings[index];
      }
    }
    return null;
  };

  return (
    <Modal className={"feedback-modal CustomeFeedBackModel"}
      isOpen={isOpen}
      style={{
        overlay: {
          padding: "20px",
          background: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          borderRadius: "30px",
          height: "fit-content",
          // width: "90%",
          position: "absolute",
          top: "50%",

        },
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <CloseIcon
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <div className="feedback-popup-container">
          {/* <FeedBackIcon /> */}
          <div className="ModelIcon">
              <img src={FeedBackModelIcon} />
          </div>
          {/* <h1> Got ideas for us?</h1>
          <p> Help us perfect the booking process by sharing your thoughts!"</p> */}
          <h1>Leave a Review</h1>
          <p>Thank you for completing the consent form for {BusinessName}. Your support means the world to us!</p>
          <p>Have you visited {BusinessName} before? If yes, we’d love to hear about your experience. Sharing your review helps others discover and enjoy our services just like you did. It truly makes a difference in supporting our business.</p>
          <div
            style={{
              display: "flex",
              width: "50%",
              justifyContent: "center",
              gap: "15px",
              alignItems: "center",

            }}
          >
            {symbol.map((item, i) => (
              <p
                onClick={() => {
                  handleShowTextArea(item);
                }}
                style={{
                  opacity: selectedEmoji === item ? 0.5 : 1,
                  cursor: "pointer",
                }}
              >
                {item}
              </p>
            ))}
          </div>
          {isVisible && (
            <div className="text-area-content">
              <textarea
                value={feedbackText}
                onChange={(e) => setFeedBackText(e.target.value)}
                // placeholder="Drop us your thoughts and help us make the booking process better."
                placeholder="We'd love to hear your feedback from your experience with us."
              />
              
                  <div className="ai-discripion-div">
                    <StepButton blue={true} isLoading={false} label={"Generate via AI"} className={"px-2"} onClick={handleGenerateBookingReviewContentDataFunc} />
                  </div>
            </div>
          )
          }
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <StepButton blue={true} disabled={feedbackText.length === 0 ? true : false} isLoading={false} label={"Send feedback"} onClick={handleFeedBack} />
          </div>
          {/* <p>Selected Rating: {getSelectedRating()}</p> */}
        </div>
      </>
    </Modal>
  );
};

export default FeedBackModal;