import { CREATE_BOOKING_ID, GET_BOOKING_ID, SET_BOOKING_DETAILS } from "../../constants"

const initialState = {
    CreateBookingDetails: null,
    GetBookingId: null,
    GetBookingDetails: null
}

export const BookingIdDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_BOOKING_ID:
            return {
                ...state,
                CreateBookingDetails: action.payload
            }

        case GET_BOOKING_ID:
            return {
                ...state,
                GetBookingId: action.payload?.data
            }

        case SET_BOOKING_DETAILS:
            return {
                ...state,
                GetBookingDetails: action.payload
            }
        default:
            return state
    }

}