// src/utils/favicon.js

export function setFavicon(url) {
    const link = document.createElement('link');
    const oldLink = document.querySelector('link[rel="icon"]');
    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    link.href = url;

    if (oldLink) {
        document.head.removeChild(oldLink);
    }

    document.head.appendChild(link);
}
