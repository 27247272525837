import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
// import { toast } from "react-toastify";
import toast from 'react-hot-toast';
import { useDispatch } from "react-redux";
import { SET_AUTH_FLAG, USER_PROFILE_DATA } from "../../constants";


// ============================SIGNUP API============================

const SIGNUP_API_MUTATION = gql`
mutation UserRegister($email: String!, $password: String!, $fname: String, $lname: String, $platform: String) {
  userRegister(email: $email, password: $password, fname: $fname, lname: $lname, platform: $platform) {
    success
    message
    data {
      user {
        usr_id
        usr_fname
        usr_lname
        usr_name
        usr_email
        usr_email_verified
        usr_country_name
        usr_country_code
        usr_phone_no
        usr_phone_country
        usr_phone_verified
        usr_dob
        usr_last_login
        usr_last_login_type
        usr_country
        usr_company_name
        usr_no_of_employees
        usr_vat_no
        userRoles
        image_url
      }
      email_exists
      token
      email_verified
      affiliation
    }
  }
}
`

export const SignupDataAction = () => {
  const [signupDataResponseFunction, signupReponseData] = useMutation(SIGNUP_API_MUTATION)

  //this will be called from components
  const signupData = signupReponseData //variable

  const initSignupDataResponse = (data, successCallback, errorCallback) => {

    signupDataResponseFunction({
      variables: { ...data, platform: 'web' }
    }).then(({ data: { userRegister: responseData } }) => {
      if (responseData.success) {
        toast.success(responseData.message)
        successCallback()
        // dispatch({
        //   type: GET_BOOKING_ID,
        //   payload: responseData
        // })

      }
      else {
        toast.error(responseData.message)
        errorCallback(responseData?.data)
      }
    }).catch(() => {
      toast.error("something went wrong!!!")
    })
  }

  return { signupData, initSignupDataResponse }
}

// ==============================================================================



// ============================EMAIL VERIFICATION API============================

const EMAIL_VERIFY_API_MUTATION = gql`
mutation VerifyEmailVerificationCode($email: String, $code: String) {
  verifyEmailVerificationCode(email: $email, code: $code) {
    success
    message
    data {
      user {
        usr_id
        usr_fname
        usr_lname
        usr_name
        usr_email
        usr_email_verified
        usr_country_name
        usr_country_code
        usr_phone_no
        usr_phone_country
        usr_phone_verified
        usr_dob
        usr_last_login
        usr_last_login_type
        usr_country
        usr_company_name
        usr_no_of_employees
        usr_vat_no
        userRoles
        image_url
      }
      token
    }
  }
}
`

export const EmailVerifyAction = () => {
  const [emailVerificationResponseFunction, emailVerificationReponseData] = useMutation(EMAIL_VERIFY_API_MUTATION)

  //this will be called from components
  const emailVerificationData = emailVerificationReponseData //variable

  const initEmailVerificationResponse = (data, successCallback, errorCallback) => {

    emailVerificationResponseFunction({
      variables: data
    }).then(({ data: { verifyEmailVerificationCode: responseData } }) => {
      if (responseData.success) {
        toast.success(responseData.message)
        successCallback()

      }
      else {
        errorCallback()
        toast.error(responseData.message)
      }
    }).catch(() => {
      toast.error("something went wrong!!!")
    })
  }

  return { emailVerificationData, initEmailVerificationResponse }
}

// ==============================================================================


// ===========================RESEND VERIFICATION CODE===========================

const RESEND_VERIFICATION_CODE_API_MUTATION = gql`
mutation SendEmailVerification($email: String!) {
  sendEmailVerification(email: $email) {
    success
    message
  }
}
`
export const ResendVerificationCodeDataAction = () => {
  const [resendVerificationCodeDataResponseFunction, resendVerificationCodeReponseData] = useMutation(RESEND_VERIFICATION_CODE_API_MUTATION)

  //this will be called from components
  const resendVerificationCodeData = resendVerificationCodeReponseData //variable

  const initResendVerificationCodeResponse = (data, successCallback, errorCallback) => {

    resendVerificationCodeDataResponseFunction({
      variables: data
    }).then(({ data: { sendEmailVerification: responseData } }) => {
      if (responseData.success) {
        toast.success(responseData.message)
        // dispatch({
        //   type: GET_BOOKING_ID,
        //   payload: responseData
        // })

      }
      else {
        toast.error(responseData.message)
      }
    }).catch(() => {
      toast.error("something went wrong!!!")
    })
  }

  return { resendVerificationCodeData, initResendVerificationCodeResponse }
}

// ==================================================================

// ============================LOGIN API============================

const LOGIN_API_MUTATION = gql`
mutation UserLogin($email: String!, $password: String!, $session_id: String) {
  userLogin(email: $email, password: $password, session_id: $session_id) {
    success
    message
    data {
      user {
        usr_id
        usr_fname
        usr_lname
        usr_name
        usr_email
        usr_email_verified
        usr_country_name
        usr_country_code
        usr_phone_no
        usr_phone_country
        usr_phone_verified
        usr_dob
        usr_last_login
        usr_last_login_type
        usr_country
        usr_company_name
        usr_no_of_employees
        usr_vat_no
        userRoles
        image_url
      }
      token
      user_inactive
      is_user_exist
      user_email_unverified
    }
  }
}
`

export const LoginDataAction = () => {
  const [loginDataResponseFunction, loginReponseData] = useMutation(LOGIN_API_MUTATION)
  const dispatch = useDispatch()

  //this will be called from components
  const loginApiData = loginReponseData //variable

  const initLoginDataResponse = (data, successCallback, errorCallback) => {

    loginDataResponseFunction({
      variables: data
    }).then(({ data: { userLogin: responseData } }) => {
      if (responseData.success) {
        toast.success(responseData.message)
        dispatch({
          type: SET_AUTH_FLAG,
          payload: true
        })
        dispatch(({
          type: USER_PROFILE_DATA,
          payload: responseData?.data?.user
        }))
        successCallback(responseData?.data);

      }
      else {
        toast.error(responseData.message)
        errorCallback(responseData?.data)
      }
    }).catch(() => {
      toast.error("something went wrong!!!")
    })
  }

  return { loginApiData, initLoginDataResponse }
}

// ==============================================================================


// ===========================FORGET PASSWORD===========================

const FORGET_PASSWORD_API_MUTATION = gql`
mutation UserInititateForgotPassword($email: String) {
  userInititateForgotPassword(email: $email) {
    success
    message
  }
}
`

export const ForgetPasswordDataAction = () => {
  const [forgetPasswordDataResponseFunction, forgetPasswordReponseData] = useMutation(FORGET_PASSWORD_API_MUTATION)

  //this will be called from components
  const forgetPasswordData = forgetPasswordReponseData //variable

  const initForgetPasswordResponse = (data, successCallback, errorCallback) => {

    forgetPasswordDataResponseFunction({
      variables: data
    }).then(({ data: { userInititateForgotPassword: responseData } }) => {
      if (responseData.success) {
        toast.success(responseData.message)
        successCallback()
      }
      else {
        toast.error(responseData.message)
      }
    }).catch(() => {
      toast.error("something went wrong!!!")
    })
  }

  return { forgetPasswordData, initForgetPasswordResponse }
}

// ==================================================================


// ===========================RESET NEW PASSWORD===========================

const RESET_NEW_PASSWORD_API_MUTATION = gql`
mutation ProcessForgotPassword($email: String, $code: String, $changePassword: Boolean, $password: String) {
  processForgotPassword(email: $email, code: $code, changePassword: $changePassword, password: $password) {
    success
    message
  }
}
`
export const ResetNewPasswordDataAction = () => {
  const [resetNewPasswordDataResponseFunction, resetNewPasswordReponseData] = useMutation(RESET_NEW_PASSWORD_API_MUTATION)

  //this will be called from components
  const resetNewPasswordData = resetNewPasswordReponseData //variable

  const initResetNewPasswordResponse = (data, successCallback, errorCallback) => {

    resetNewPasswordDataResponseFunction({
      variables: data
    }).then(({ data: { processForgotPassword: responseData } }) => {
      if (responseData.success) {
        toast.success(responseData.message)
        successCallback()

      }
      else {
        toast.error(responseData.message)
        errorCallback()
      }
    }).catch(() => {
      toast.error("something went wrong!!!")
    })
  }

  return { resetNewPasswordData, initResetNewPasswordResponse }
}
