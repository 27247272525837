import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./app.scss";
// import AcademyHomePage from "./pages/AcademyHomePage";
import LandingScreen from "./pages/LandingScreen";
import checkedpng from "../src/assets/images/cart/Checked.png";
import {
  payl8r,
  aboutUs,
  clinicCart,
  contactUs,
  errorPage,
  indexPattern,
  privacyPolicy,
  termsConditions,
  treatmentDetails,
  treatmentListing,
  clinicCartStatus,
} from "./Routes";
// import CourseListing from "./pages/listing/CourseListing";
// import CourseDetails from "./pages/details/course-details/CourseDetails";
import Cookies from "js-cookie";
import { lazy, Suspense, useEffect, useState } from "react";
// import AcademyCart from "./pages/cart/academy-cart/AcademyCart";
// import Dashboard from "./pages/dashboard/Dashboard";
// import CourseModules from "./pages/course-modules/CourseModules";
// import CourseExam from "./pages/course-exam/CourseExam";
// import MyBookings from "./pages/my-bookings/MyBookings";
// import { toast, ToastContainer } from "react-toastify";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector, useDispatch } from "react-redux";
import { socket } from "./socket";
import { setFavicon } from "../src/utils/favicon";

import { v4 as uuidv4 } from "uuid";
import Loader from "./components/loader/Loader";
import { GetWebsiteDetailsAction } from "./redux/actions/create-website/getWebsiteDetails";
import { GetPersonalWebiteConfigsAction } from "./redux/actions/create-website/FrontGetPersonalWebsiteConfigs";
import { MetaTags } from "react-meta-tags";
import PayLaterSuccessPage from "./components/payl8r-successPage/PayLaterSuccessPage";
const ClinicCart = lazy(() => import("./pages/cart/clinic-cart/ClinicCart"));
const ClinicHomePage = lazy(() => import("./pages/ClinicHomePage"));
const TreatmentDetails = lazy(() =>
  import("./pages/details/treatment-details/TreatmentDetails")
);
const TreatmentListing = lazy(() => import("./pages/listing/TreatmentListing"));
const ErrorPage = lazy(() => import("./components/error-page/ErrorPage"));
const NotificationPopup = lazy(() =>
  import("./components/notification-popup/NotificationPopup")
);
const Aboutus = lazy(() => import("./pages/cms-pages/about-us/Aboutus"));
const PrivacyPolicy = lazy(() =>
  import("./pages/cms-pages/privacy-policy/PrivacyPolicy")
);
const TermsConditions = lazy(() =>
  import("./pages/cms-pages/terms-conditions/TermsConditions")
);
const ContactUs = lazy(() => import("./pages/conatct-us/ContactUs"));

const Payl8rPopUp = lazy(() => import("./pages/payl8r-pop-up/Pyl8rPopup"));

export let cookie_auth;
const routerParams = {};

if (process.env.PUBLIC_URL) {
  routerParams["basename"] = process.env.PUBLIC_URL;
}

export function generateRandomString() {
  // let result = '';
  // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // const charactersLength = characters.length;
  // let counter = 0;
  // while (counter < length) {
  //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   counter += 1;
  // }
  // return result;
  return uuidv4();
}

if (sessionStorage.getItem("sessionString") === null) {
  sessionStorage.setItem("sessionString", generateRandomString());
}

function App() {
  const websiteApiData = useSelector(
    (state) => state.WebsiteDetailsReducer.websiteApiData
  );

  const currentURL = window.location.href;
  const urlObject = new URL(currentURL);
  const domain = urlObject.hostname;

  const [isConnected, setIsConnected] = useState("");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const websiteApiStatus = useSelector(
    (state) => state.WebsiteDetailsReducer.websiteApiStatus
  );

  const {
    initGetWebsiteDataResponseFunction,
    GetWebsiteDetailsResponseData: { loading: homeScreenLoading },
  } = GetWebsiteDetailsAction();

  const {
    initGetWebsiteConfigsResponseFunction,
    getPersonalWebiteConfigsResponseData: { loading: websiteConfigsLoading },
  } = GetPersonalWebiteConfigsAction();
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    initGetWebsiteDataResponseFunction();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on("connect", onConnect);
    socket.on("connect_error", (err) => { });

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  useEffect(() => {
    socket.on("broadcast-notice", (event) => {
      if (
        event.domain == domain &&
        event.mode == process.env.REACT_APP_ORDER_POPUP_MODE &&
        (event.type == "course-purchase" || event.type == "treatment-purchase")
      ) {
        toast(<NotificationPopup eventData={event?.data} />, {
          position: "bottom-left",
          autoClose: 5000,
          className: "order",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    });
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off("broadcast-notice");
    };
  }, [isConnected]);

  cookie_auth = useSelector((state) => state?.AuthDataReducer?.checkAuth);

  useEffect(() => { }, [
    Cookies.get("token", { domain: `.${window.location?.hostname}` }),
    cookie_auth,
    Cookies.get("faces_access_token"),
  ]);

  console.log(websiteApiData?.logo_image_url, "websiteApiData?.logo_image_url");

  useEffect(() => {
    if (websiteApiData?.logo_image_url?.length > 0) {
      setFavicon(websiteApiData?.logo_image_url);
    }
    // if (websiteApiData?.business_name?.length > 0) {
    //   document.title = websiteApiData?.business_name;
    // }
  }, [websiteApiData]);

  console.log(websiteApiData, "websiteApiData");
  return (
    <>
      {websiteApiStatus == null ? (
        <div className="LoaderMain">
          <Loader isActive={websiteApiData ? true : false} />
        </div>
      ) : (
        <>
          {/* <Helmet>
              <title>{websiteApiData?.business_name || ""}</title>
              <meta name="description" content={websiteApiData?.headline || ""} />
              <meta property="og:image" content={checkedpng} />
              <meta property="og:title" content={"hello"} />
              <meta property="og:description" content={"app facesconsent"} />

              <meta name="twitter:title" content={websiteApiData?.business_name || ""} />
              <meta name="twitter:description" content={websiteApiData?.headline || ""} />
              <meta name="twitter:image" content={websiteApiData?.logo_image_url || ""} />

            </Helmet> */}

          <Suspense fallback={<Loader isActive={true} />}>
            <Router {...routerParams}>
              <Routes>
                {websiteApiStatus && websiteApiData?.is_active ? (
                  <Route
                    exact
                    path={indexPattern}
                    element={
                      <ClinicHomePage homeScreenLoading={homeScreenLoading} />
                    }
                  />
                ) : (
                  <Route exact path={errorPage} element={<ErrorPage />} />
                )}

                <Route exact path={errorPage} element={<ErrorPage />} />

                {/* <Route
       exact
       path={academyHome}
       element={
         <AcademyHomePage />
       }
     /> */}
                {/* <Route
       exact
       path={courseListing}
       element={
         <CourseListing />
       }
     /> */}
                {/* <Route
       exact
       path={courseDetails}
       element={
         <CourseDetails />
       }
     /> */}
                {/* <Route
       exact
       path={academyCart}
       element={
         <AcademyCart />
       }
     /> */}
                {/* <Route
       exact
       path={dashboard}
       element={
         <Dashboard auth={cookie_auth} />
       }
     /> */}
                {/* <Route
       exact
       path={courseModules}
       element={
         <CourseModules auth={cookie_auth} />
       }
     /> */}
                {/* <Route
       exact
       path={courseExam}
       element={
         <CourseExam auth={cookie_auth} />
       }
     /> */}
                {/* <Route
       exact
       path={clinicHome}
       element={
         <ClinicHomePage />
       }
     /> */}
                <Route
                  exact
                  path={treatmentListing}
                  element={<TreatmentListing />}
                />
                <Route
                  exact
                  path={treatmentDetails}
                  element={<TreatmentDetails />}
                />
                <Route exact path={contactUs} element={<ContactUs />} />
                <Route exact path={clinicCart} element={<ClinicCart />} />
                {/* <Route
       exact
       path={myBookings}
       element={
         <MyBookings />
       }
     /> */}

                <Route exact path={aboutUs} element={<Aboutus />} />
                <Route exact path={privacyPolicy} element={<PrivacyPolicy />} />
                <Route
                  exact
                  path={termsConditions}
                  element={<TermsConditions />}
                />
                <Route
                exact
                path={clinicCartStatus}
                element={<PayLaterSuccessPage />}
              />
              </Routes>
            </Router>
          </Suspense>
          <Toaster
            position={screenWidth > 576 ? "top-right" : "top-center"}
            reverseOrder={false}
            gutter={0}
            toastOptions={{
              duration: 2000,
              style: {
                padding: "15px 30px",
                color: "#000000",
              },
            }}
          />
        </>
      )}
    </>
  );
}

export default App;
