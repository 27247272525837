export const GET_WEBSITE_DETAILS = "GET_WEBSITE_DETAILS"
export const GET_TEAM_MEMBER = "GET_TEAM_MEMBER"

export const GET_PERSONAL_WEBSITE_CONFIGS = "GET_PERSONAL_WEBSITE_CONFIGS"
export const GET_PERSONAL_WEBSITE_TEAM_MEMBERS = "GET_PERSONAL_WEBSITE_TEAM_MEMBERS"

export const GET_WEBSITE_DETAILS_STATUS = "GET_WEBSITE_DETAILS_STATUS"

export const GET_TERMS_AND_CONDITION_DATA = "GET_TERMS_AND_CONDITION_DATA"

