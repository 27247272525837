import { GET_BUSINESS_CLINIC_LIST } from "../../constants/business-location-const/BusinessLocationConst";
import { GET_TREATMENTS_FOR_USER, SET_LAST_STEP} from "../../constants/treatment-list-const/TreatmentListConst";

const initialState = {
  businessClinicList: null,
  treatmentsForUser: [],
  deleteStatus: false,
  totalTreatments: 0,
  loadedTreatmentsCount: 0,
  clinicListPract: [],
  totalClinics: 0,
  loadedClinicsCount: 0,
  treatmentList: [],
  business_name: "",
  clinic_id : 0,

  isLastStep: false,
};

export default function businessLocationListReducer(state = initialState, action) {

  switch (action.type) {

    case GET_BUSINESS_CLINIC_LIST:

      let page1 = action?.page
      let tempLoadedClinics = action?.payload;
      let tempTotalClinics = state?.loadedClinicsCount + tempLoadedClinics?.items?.length;

      let clinicsUpdatedResponse;
      if (page1 == 1) {
        clinicsUpdatedResponse = action?.payload
      }
      else {
        clinicsUpdatedResponse = [
          ...state.clinicListPract,
          ...action.payload?.items
        ]
      }

      return {
        ...state,
        clinicListPract: clinicsUpdatedResponse,
        businessClinicList: action.payload,
        totalClinics: action.payload.total,
        loadedClinicsCount: tempTotalClinics,
        business_name: action.payload?.company_name,
        clinic_id : action.payload?.clinic_id
      };
      break;



    case GET_TREATMENTS_FOR_USER:
      // let page = action?.page
      // let tempLoadedItems = action.payload.items;
      // let tempLoadedtotal = state.loadedTreatmentsCount + tempLoadedItems?.length;
      // let updatedResponse1

      // let categoriesArray = [];

      // tempLoadedItems.forEach(element => 
      //   element?.treatment_category.forEach((item)=>
      //   (!categoriesArray?.includes(item) && item != null) &&
      //     categoriesArray.push(item)
      //   )
      //   );
      // let newTreatmentList = categoriesArray.map((element)=>{
      //   let treatments = [];
      //   tempLoadedItems.forEach((item)=>
      //     item?.treatment_category?.includes(element) && treatments.push(item)
      //   )
      //   return(
      //     {
      //       categoryName : element,
      //       treatments : treatments
      //     }
      //   )
      // })


      // if (page == 1) {
      //   updatedResponse1 = action.payload.items
      // }
      // else {
      //   updatedResponse1 = [
      //     ...state.treatmentsForUser,
      //     ...action.payload.items
      //   ]
      // }
      // return {
      //   ...state,
      //   newTreatmentList: newTreatmentList,
      //   treatmentsForUser: updatedResponse1,
      //   totalTreatments: action.payload.total,
      //   loadedTreatmentsCount: tempLoadedtotal,
      // }

      return {
        ...state,
        treatmentList: action.payload,
      };

    // case DELETE_SELECTED_TREATMENTS:
    //   let selectedIds = action.payload;
    //   let updatedResponse = state.treatmentsForUser.filter((item) =>
    //     selectedIds.includes((item?.treatment_id)) != true
    //   )
    //   return {
    //     ...state,
    //     treatmentsForUser: updatedResponse,
    //   }

    // case DELETE_SELECTED_TREATMENTS_SUCCESS:
    //   return {
    //     ...state,
    //     deleteStatus: action.payload,
    //   }

    case SET_LAST_STEP:
      return {
        ...state,
        isLastStep: action.payload,
      }


    default:
      return state;
  }



}
