import React, { useEffect } from "react";
import "./footer.scss";
import footerkanvas from "../../assets/images/footer/F-Logo.svg";
// import { ReactComponent as Facebook } from '../../assets/images/icons/footer/facebook.svg';
import { ReactComponent as Instagram } from "../../assets/images/icons/footer/instagram.svg";
import { ReactComponent as Twitter } from "../../assets/images/icons/footer/twitter.svg";
import { ReactComponent as Linkdin } from "../../assets/images/icons/footer/linkdin.svg";
import { useNavigate } from "react-router";
import { aboutUs, contactUs, indexPattern, privacyPolicy, termsConditions } from "../../Routes";
import { useSelector } from "react-redux";
import { Web_Base_Url } from "../../config";

const Footer = (props) => {
  const navigate = useNavigate();

  const websiteApiData = useSelector(
    (state) => state.WebsiteDetailsReducer.websiteApiData
  );

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const routeTags = document.querySelectorAll(".routes-tag");
    routeTags.forEach((tag) => {
      tag.addEventListener("click", scrollToTop);
    });
    return () => {
      routeTags.forEach((tag) => {
        tag.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  return (
    <>
      <div
        style={{ background: websiteApiData?.primary_color }}
        className="footer-div"
      >
        <div className="container-xxl">
          <div className="d-flex flex-column justify-content-cente align-items-center">
            <div className="kanvas-image">
              <img src={websiteApiData?.logo_image_url} alt="kanvaslogo" />
            </div>
            <div className="social-media-icons">
              <div className="d-flex cursor-pointer">
                <div
                  className="social-media-icon"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/" + websiteApiData?.facebook,
                      "_blank"
                    )
                  }
                >
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="25"
                      cy="25"
                      r="24.5"
                      stroke={websiteApiData?.secondary_color}
                    />
                    <path
                      d="M34.9163 29.8646C34.9163 33.6563 32.6559 35.9167 28.8643 35.9167H27.6247C27.0518 35.9167 26.583 35.448 26.583 34.875V28.8646C26.583 28.5834 26.8122 28.3438 27.0934 28.3438L28.9268 28.3125C29.0726 28.3021 29.1976 28.198 29.2288 28.0521L29.5934 26.0625C29.6005 26.0174 29.5977 25.9714 29.5853 25.9274C29.5728 25.8835 29.551 25.8428 29.5213 25.8082C29.4916 25.7735 29.4547 25.7457 29.4132 25.7267C29.3717 25.7077 29.3266 25.6979 29.2809 25.698L27.0622 25.7292C26.7705 25.7292 26.5413 25.5 26.5309 25.2188L26.4893 22.6667C26.4893 22.5 26.6247 22.3542 26.8018 22.3542L29.3018 22.3125C29.4788 22.3125 29.6143 22.1771 29.6143 22L29.5726 19.5C29.5726 19.323 29.4372 19.1875 29.2601 19.1875L26.4476 19.2292C26.037 19.2354 25.6317 19.3226 25.2549 19.4858C24.8781 19.6489 24.5372 19.8849 24.2518 20.18C23.9663 20.4751 23.7419 20.8237 23.5914 21.2057C23.4409 21.5878 23.3672 21.9957 23.3747 22.4063L23.4268 25.2709C23.4372 25.5625 23.208 25.7917 22.9163 25.8021L21.6663 25.823C21.4893 25.823 21.3538 25.9584 21.3538 26.1355L21.3851 28.1146C21.3851 28.2917 21.5205 28.4271 21.6976 28.4271L22.9476 28.4063C23.2393 28.4063 23.4684 28.6355 23.4788 28.9167L23.5726 34.8542C23.583 35.4375 23.1143 35.9167 22.5309 35.9167H20.1351C16.3434 35.9167 14.083 33.6563 14.083 29.8542V21.1355C14.083 17.3438 16.3434 15.0834 20.1351 15.0834H28.8643C32.6559 15.0834 34.9163 17.3438 34.9163 21.1355V29.8646Z"
                      fill={websiteApiData?.secondary_color}
                    />
                  </svg>
                </div>
                <div
                  className="social-media-icon"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/" + websiteApiData?.instagram,
                      "_blank"
                    )
                  }
                >
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="25" cy="25" r="24.5" stroke={websiteApiData?.secondary_color} />
                    <path
                      d="M28.8643 15.0834H20.1351C16.3434 15.0834 14.083 17.3438 14.083 21.1355V29.8542C14.083 33.6563 16.3434 35.9167 20.1351 35.9167H28.8538C32.6455 35.9167 34.9059 33.6563 34.9059 29.8646V21.1355C34.9163 17.3438 32.6559 15.0834 28.8643 15.0834ZM24.4997 29.5417C22.2705 29.5417 20.458 27.7292 20.458 25.5C20.458 23.2709 22.2705 21.4584 24.4997 21.4584C26.7288 21.4584 28.5413 23.2709 28.5413 25.5C28.5413 27.7292 26.7288 29.5417 24.4997 29.5417ZM30.6663 20.1667C30.6143 20.2917 30.5413 20.4063 30.4476 20.5105C30.3434 20.6042 30.2288 20.6771 30.1038 20.7292C29.9143 20.8097 29.705 20.8318 29.5028 20.7929C29.3006 20.7539 29.1145 20.6556 28.9684 20.5105C28.8747 20.4063 28.8018 20.2917 28.7497 20.1667C28.6962 20.0415 28.6679 19.907 28.6663 19.7709C28.6663 19.6355 28.6976 19.5 28.7497 19.375C28.8018 19.2396 28.8747 19.1355 28.9684 19.0313C29.208 18.7917 29.5726 18.6771 29.9059 18.75C29.9788 18.7605 30.0413 18.7813 30.1038 18.8125C30.1663 18.8334 30.2288 18.8646 30.2913 18.9063C30.3434 18.9375 30.3955 18.9896 30.4476 19.0313C30.5413 19.1355 30.6143 19.2396 30.6663 19.375C30.7184 19.5 30.7497 19.6355 30.7497 19.7709C30.7497 19.9063 30.7184 20.0417 30.6663 20.1667Z"
                      fill={websiteApiData?.secondary_color}
                    />
                  </svg>

                  {/* <Instagram /> */}
                </div>
                {/* <a className='social-media-icon' href="https://twitter.com/kanvascosmetic?lang=en" target='_blank'><Twitter /></a> */}
                {/* <a className='social-media-icon' href="https://uk.linkedin.com/company/kanvasmedicalgroup" target='_blank'><Linkdin /></a> */}
              </div>
            </div>
            <div className="routes-div d-flex flex-column flex-sm-row">
              {/* <div className='routes-tag' onClick={() => navigate(indexPattern)}>
                                <span>Home</span>
                            </div> */}
              {/* <div className='routes-tag' onClick={() => window.open(`${process.env.REACT_APP_LIVE_URL}/blog`, '_blank')}>
                                <span>News</span>
                            </div> */}
              {/* <div className="routes-tag" onClick={() => navigate(aboutUs)}>
                <span style={{ color: websiteApiData?.secondary_color }}>
                  About us
                </span>
              </div> */}
              <div
                className="routes-tag"
                onClick={() => navigate(termsConditions)}
              >
                <span style={{ color: websiteApiData?.secondary_color }}>
                  Terms and Conditions
                </span>
              </div>
              {/* <div className="routes-tag" onClick={() => navigate(contactUs)}>
                <span style={{ color: websiteApiData?.secondary_color }}>
                  Contact us
                </span>
              </div> */}
            </div>

            <div className="hr-div w-100">
              <hr />
            </div>

            <div className="copyrights">
              <span style={{ color: websiteApiData?.secondary_color }}>
                © Copyright {new Date().getFullYear()} • All Rights Reserved.
              </span>
            </div>
            <div className="poweredby">
              <span>
                <a
                  // href={Web_Base_Url}
                  href="https://facesconsent.com/"
                  target="_blank"
                  style={{ color: websiteApiData?.secondary_color }}
                >
                  Powered by Faces
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
