import { GET_SPRINT_BOOKING_LINK_DETAILS_DATA } from "../../constants/treatment-listing/getSprintBookingLinkDetails";

const initialState = {
    sprintBookingDetails: {
        paymentSummary: {
            totalAmount: null,
            depositAmount: null,
            payableAmount: null,
            dueAmount: null,
            discountAmount: null
        },
        payment_data: {
            pay_in_full: false,
            pay_deposit: false,
            pay_on_the_day: true,
            pay_monthly: false,
            is_finance_available: false,
            is_stripe_setup: false,
            is_ryft_setup: false,
            user_payl8tr_status: 1
        }
    }
}

export const getSprintBookingLinkDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SPRINT_BOOKING_LINK_DETAILS_DATA:
            return {
                ...state,
                sprintBookingDetails: action?.payload?.data
            };
        default:
            return state
    }
}