import {
  CLEAR_TREATEMENT_LIST,
  GET_TREATMENT_LISTING_CONST,
} from "../../constants";

const initialState = {
  treatmentsList: [],
  totalTreatmentsCount: 0,
  loadedTreatmentsCount: 0,
  treatmentListPage: 0,
};
export const GetTreatmentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TREATMENT_LISTING_CONST:
      let temp = [...state.treatmentsList, ...action.payload.data.items];

      return {
        ...state,
        treatmentsList: temp,
        totalTreatmentsCount: action.payload.data.total,
        loadedTreatmentsCount: temp.length,
        treatmentListPage: state.treatmentListPage + 1
      };

    case CLEAR_TREATEMENT_LIST:
      return {
        ...state,
        treatmentsList: [],
        totalTreatmentsCount: 0,
        loadedTreatmentsCount: 0,
        treatmentListPage: 0,
      };
    default:
      return state;
  }
};
