import React, { useEffect, useRef, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import './ratingcard.scss'
import googleicon from '../../assets/images/landing-page/googleicon.svg'
import facesicon from '../../assets/images/landing-page/facesIcon.png'
import insyncIcon from "../../assets/images/landing-page/insyncIcon.png"
import profileImage from '../../assets/images/landing-page/profile-image.jpg'
import CustomModal from '../modal/CustomModal'
import UserImage from "../../assets/images/landing-page/profile-image.jpg";
import staricon from "../../assets/images/landing-page/star1.png";
import GoogleIcon from "../../assets/images/landing-page/googleicon.svg"
import Avatar from "react-avatar";
import { useSelector } from "react-redux";



const Ratingcard = ({ listItem, type, style }) => {
    const [reviewModalOpen, setReviewModalOpen] = useState(false);
    const paragraphRef = useRef(null);
    const [ismore, setIsmore] = useState(false)
    const websiteApiData = useSelector(
        (state) => state.WebsiteDetailsReducer.websiteApiData
    );



    useEffect(() => {
        const paragraph = paragraphRef.current;

        if (paragraph.textContent.length > 96) {
            setIsmore(true)
        }

    }, [])

    const userFullName = listItem.userName.split(" ").filter((v) => v != "")
    let userName = userFullName[0][0].toUpperCase() + userFullName[0].slice(1);
    if(userFullName.length == 2) userName += " " + userFullName[1][0].toUpperCase() + ".";


    return (
        <>
            <div className="rating-card-div" style={style}>
                <div className="user-info-div ">
                    <div className='d-flex align-items-top justify-content-between'>
                        <div className='d-flex '>
                            <div className="profile-image me-2">
                                {/* <img src={listItem?.userImage} alt="profileImage" /> */}
                                <Avatar name={listItem?.userName[0]} src={listItem?.userImage} border="0" round size="40" color="#EEEFF3" fgColor="#000" textSizeRatio={2} className="avtar-image"  />
                            </div>
                            <div className="user-name">
                                <div className=''>
                                    <p className='use-title'>{userName}</p>
                                    <p className='use-date'>
                                        {listItem?.reviewDate}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='google-icon-image'>
                            {
                                type == 'faces' ?
                                    <img src={websiteApiData?.created_from === "faces" ? facesicon : insyncIcon} alt="googleicon" />
                                    :
                                    <img src={googleicon} alt="googleicon" />
                            }
                        </div>
                    </div>

                </div>
                <div className="ratings-div mt-2">
                    <Rating initialValue={listItem?.userRating} readonly={true} allowFraction size={26} />
                </div>
                <div className="content mt-2">
                    <p ref={paragraphRef} className='mb-0' >
                        {listItem?.userReview}
                    </p>
                </div>
                <div className='text-end'>
                    <span className={`view-moreBtn ${ismore ? "" : "v-hide"}`} onClick={() => setReviewModalOpen(true)}>view more</span>
                </div>

            </div>

            <CustomModal modalOpen={reviewModalOpen} setModalOpen={setReviewModalOpen} type={"review"} modalBody={<>
                <div className='review-modal-body'>
                    <div className='user-image border'>
                        {/* <img src={listItem?.userImage} /> */}
                        <Avatar name={listItem?.userName[0]} src={listItem?.userImage} border="0" round size="100" color="#EEEFF3" fgColor="#000" textSizeRatio={2} className="avtar-image"  />
                        {
                            type == 'faces' ?
                                <img className='google-icon' src={facesicon} alt="googleicon" />
                                :
                                <img className='google-icon' src={googleicon} alt="googleicon" />
                        }
                    </div>
                    <h4 className='text-center mt-2 user-name'>{userName}</h4>
                    <p className='review-time mb-0'>{listItem?.reviewDate}</p>
                    <div className='d-flex justify-content-center pb-3'>
                        <Rating initialValue={listItem?.userRating} readonly={true} allowFraction size={26} />
                    </div>

                    <div className='review-text'>
                        <p className='text-center'>
                            {listItem?.userReview}
                        </p>
                    </div>
                </div>
            </>} />
        </>
    )
}

export default Ratingcard