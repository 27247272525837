import { CLEAR_COURSE_LIST, GET_COURSE_LISTING_CONST } from "../../constants";

const initialState = {
    coursesList: [],
    totalCoursesCount: 0,
    loadedCoursesCount: 0,
    courseListPage: 1,
}
export const GetCourseListReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_COURSE_LISTING_CONST:
            let temp = (state?.coursesList).concat(action.payload.data?.courses)

            return {
                ...state,
                coursesList: temp,
                totalCoursesCount: action.payload.data?.totalCourses,
                loadedCoursesCount: temp.length,
                courseListPage: action.payload.data?.currentPage,
            };

        case CLEAR_COURSE_LIST:
            return {
                ...state,
                coursesList: [],
                totalCoursesCount: 0,
                loadedCoursesCount: 0,
                courseListPage: 1,
            };
        default:
            return state
    }
}  