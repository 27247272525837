import { GET_BLOGS } from "../../constants/home-page/Academy";

const initialState = {
    getBlogs: null
}

export const AcademyHomeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BLOGS:
            return {
                ...state,
                getBlogs: action?.payload?.data?.posts
            }

        default:
            return state
    }

}
