import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CLINICS, VIEW_ALL_CART_ITEMS } from "../../constants/treatment/ViewAllCart";
import Cookies from "js-cookie";
// import { toast } from "react-toastify";
import toast from 'react-hot-toast';
import { ViewAllCourseCartAction } from "../courses/ViewAllCourseCart";
import { GetCartItemsCountAction } from "../home-page/Academy";
import { GET_TOTAL_AMOUNT } from "../../constants";
import { generateRandomString } from "../../../App";

// =====================VIEW ALL CART=====================

const VIEW_ALL_CART_QUERY = gql`
query GetCartItems($sessionId: String, $referenceType: Int) {
    getCartItems(session_id: $sessionId, reference_type: $referenceType) {
      success
      message
      data {
        items {
          treatment_id
          treatment_name
          treatment_slug
          treatment_image
          treatment_price
          cartItem_id
          treatment_duration
          treatment_deposit
        }
        payment
        totalPrice
      }
    }
  }`

export const ViewAllCartAction = () => {
    const dispatch = useDispatch()
    const [getViewAllCartResponseFunction, viewAllCartResponseData] = useLazyQuery(
        VIEW_ALL_CART_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getCartItems: responseData }) => {
                if (responseData?.success) {
                    dispatch({
                        type: VIEW_ALL_CART_ITEMS,
                        payload: responseData
                    })
                    dispatch({
                        type: GET_TOTAL_AMOUNT,
                        payload: responseData
                    })
                }
            }
        }
    )
    //this will be called from components
    const viewAllCartData = viewAllCartResponseData //variable

    const initViewAllCartItems = (data) => {

        //checking guest or logged in user
        if (sessionStorage.getItem('sessionString') === null) {
            sessionStorage.setItem('sessionString', generateRandomString())
        }
        let checkTokenLength = Cookies.get('faces_login_token')?.length
        let sessionString
        if (checkTokenLength > 0) {
            sessionString = sessionStorage.getItem('sessionString')
        } else {
            sessionString = sessionStorage.getItem('sessionString')
        }
        if (sessionString)
            getViewAllCartResponseFunction({
                variables: { ...data, sessionId: sessionString }
            })
        // getViewAllCartResponseFunction({
        //     variables: { ...data, sessionId: null }
        // })
    }


    return { viewAllCartData, initViewAllCartItems }
}

// =========================================================

// =====================DELETE CART=====================

const DELETE_CART_ITEM_MUTATION = gql`
mutation DeleteCartItems($cartItemId: [Int]!, $sessionId: String) {
    deleteCartItems(cart_item_id: $cartItemId, session_id: $sessionId) {
      message
      success
    }
  }`

export const DeleteCartAction = () => {
    const { viewAllCartData, initViewAllCartItems } = ViewAllCartAction()
    const { viewAllCourseCartData, initViewAllCourseCartItems } = ViewAllCourseCartAction()
    const { initGetCartItemsCountData } = GetCartItemsCountAction()


    const [getDeleteCartResponseFunction, deleteCartResponseData] = useMutation(DELETE_CART_ITEM_MUTATION)

    //checking guest or logged in user
    let checkTokenLength = Cookies.get('faces_login_token')?.length
    let sessionString
    if (checkTokenLength > 0) {
        sessionString = sessionStorage.getItem('sessionString')
    } else {
        sessionString = sessionStorage.getItem('sessionString')
    }

    const deleteCartMutationData = deleteCartResponseData

    const initDeleteCartFunction = (data, successCallback, errorCallback) => {
        getDeleteCartResponseFunction({
            variables: { ...data?.paramData, sessionId: sessionString }
        }).then(({ data: { deleteCartItems: responseData } }) => {
            if (responseData.success) {
                if (data?.type == 1)
                    initViewAllCartItems({ referenceType: 1 })
                else if (data?.type == 2)
                    initViewAllCourseCartItems()
                // toast.success(responseData.message)
                successCallback()

                //for getting cart count
                // initGetCartItemsCountData({
                //     sessionId: `${sessionString}`
                // })
            }
            else {
                errorCallback()
                toast.error(responseData.message)
            }
        }).catch(() => {
            errorCallback()
            toast.error("something went wrong!!!")
        })
    }
    return { deleteCartMutationData, initDeleteCartFunction }
}

// =========================================================

// =====================GET CLINICS=====================
const GET_CLINICS_QUERY = gql`
query GetCartClinics($sessionId: String!) {
  getCartClinics(session_id: $sessionId) {
    success
    message
    data {
      items {
        clinic_id
        clinic_name
        clinic_address
        image
        cln_average_rating
        total_reviews
      }
      total
      is_finance_available
      is_stripe_setup
      is_ryft_setup
    }
  }
}
`
export const GetClinicsAction = () => {
    const dispatch = useDispatch()
    const [getClinicsResponseFunction, clinicsResponseData] = useLazyQuery(
        GET_CLINICS_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getCartClinics: responseData }) => {
                dispatch({
                    type: GET_CLINICS,
                    payload: responseData

                })
            }
        }
    )

    //this will be called from components
    const clinicsData = clinicsResponseData//variable

    const initTreatmentDetailsData = () => {
        //checking guest or logged in user
        let checkTokenLength = Cookies.get('faces_login_token')?.length
        let sessionString
        if (checkTokenLength > 0) {
            sessionString = sessionStorage.getItem('sessionString')
        } else {
            sessionString = sessionStorage.getItem('sessionString')
        }

        getClinicsResponseFunction({
            variables: { sessionId: sessionString }
        })
    }
    return { clinicsData, initTreatmentDetailsData }
}





// =========================================================