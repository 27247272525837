import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { SET_AUTH_FLAG, USER_PROFILE_DATA } from "../../../constants";


// ========================USER  PROFILE========================

const GET_USER_PROFILE_QUERY = gql`
query GetUserProfile {
    getUserProfile {
      success
      message
      data {
        pendingDetails
        user {
          usr_id
          usr_fname
          usr_lname
          usr_name
          usr_email
          usr_email_verified
          usr_country_name
          usr_country_code
          usr_phone_no
          usr_phone_country
          usr_phone_verified
          usr_dob
          usr_last_login
          usr_last_login_type
          usr_country
          usr_company_name
          usr_no_of_employees
          usr_vat_no
          userRoles
          image_url
        }
      }
    }
  }
`


export const GetUserProfileAction = () => {
    const dispatch = useDispatch();
    const [getUserProfileResponseFunction, UserProfileResponseData] = useLazyQuery(
        GET_USER_PROFILE_QUERY,
        {
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: ({ getUserProfile: responseData }) => {
                if (responseData.success) {
                    dispatch({
                        type: SET_AUTH_FLAG,
                        payload: true
                    })
                    dispatch({
                        type: USER_PROFILE_DATA,
                        payload: responseData?.data?.user,
                    });

                    // toast.success(responseData?.message)
                } else {
                    // toast.error(responseData?.message)
                }
            },
        }
    );

    const userProfileQueryData = UserProfileResponseData; // variable

    const initUserProfileData = (data) => {
        getUserProfileResponseFunction({
            variables: data,
        });
    };

    return { userProfileQueryData, initUserProfileData };
};


