import { CLEAR_COURSE_LIST, GET_COURSE_LISTING_CONST } from "../../constants";
import { GET_PERSONAL_WEBSITE_CONFIGS, GET_PERSONAL_WEBSITE_TEAM_MEMBERS, GET_TEAM_MEMBER, GET_TERMS_AND_CONDITION_DATA, GET_WEBSITE_DETAILS, GET_WEBSITE_DETAILS_STATUS } from "../constants";

const initialState = {
    websiteApiData: null,
    teamMembersList: null,
    websiteConfigsData: null,
    websiteTeamMembersData: null,
    websiteApiStatus: null,
    termsAndConditionData: null

}
export const WebsiteDetailsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_WEBSITE_DETAILS:

            return {
                ...state,
                websiteApiData: action.payload.data
            };
        case GET_WEBSITE_DETAILS_STATUS:

            return {
                ...state,
                websiteApiStatus: action.payload
            };

        case GET_TEAM_MEMBER:
            return {
                ...state,
                teamMembersList: action.payload.data.items,
            };
            break;

        case GET_PERSONAL_WEBSITE_CONFIGS:

            return {
                ...state,
                websiteConfigsData: action.payload.data
            };

        case GET_PERSONAL_WEBSITE_TEAM_MEMBERS:

            return {
                ...state,
                websiteTeamMembersData: action.payload.data
            };

            case GET_TERMS_AND_CONDITION_DATA:

            return {
                ...state,
                termsAndConditionData: action.payload
            };


            
        default:
            return state
    }
}  