import { GET_RYFT_PAYMENT_TOKEN } from "../../constants/kanvasTreatmentBooking/kanvasTreatmentBooking";

const initialState={
  treatmentBookingToken:""
}

export default function treatmentBookingreducer(state=initialState,action){
  switch(action.type){
    case GET_RYFT_PAYMENT_TOKEN:
      return{
        ...state,
        treatmentBookingToken:action.payload.payment_session,
        treatmentBookingAccountId:action.payload.accountId,
      };
      default:
        return state
  }
}