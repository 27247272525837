import { CLEAR_CLINICS_DATA, GET_CLINICS, VIEW_ALL_CART_ITEMS } from "../../constants/treatment/ViewAllCart"

const initialState = {
    treatmentCartItems: [],
    totalPrice: 0,
    totalDeposit: 0,
    treatmentDuration: "",
}

export const ViewAllTreatmentCartReducer = (state = initialState, action) => {
    switch (action.type) {
        case VIEW_ALL_CART_ITEMS:

            const total = action.payload?.data?.items.reduce((accumulator, object) => {
                return accumulator + object['treatment_price'];
            }, 0);
            const totalDeposit = action.payload?.data?.items.reduce((accumulator, object) => {
                return accumulator + object['treatment_deposit'];
            }, 0);
            return {
                ...state,
                treatmentCartItems: action.payload?.data?.items,
                totalPrice: total,
                totalDeposit: totalDeposit,
                treatmentDuration: action.payload?.data
            }

        default:
            return state
    }

}

const intialClinicsState = {
    clinicsDataItems: null,
    isFinanceAvailable: false,
    isRyftSetup:false,
    isStripeSetup:false,
}

export const GetAllClinicsDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLINICS:

            return {
                ...state,
                clinicsDataItems: action.payload?.data?.items,
                isFinanceAvailable: action.payload?.data?.is_finance_available,
                isRyftSetup:action?.payload?.data?.is_ryft_setup,
                isStripeSetup:action?.payload?.data?.is_stripe_setup,
            }
        case CLEAR_CLINICS_DATA:
            return {
                ...state,
                clinicsDataItems: null,
                isFinanceAvailable: false
            }

        default:
            return state
    }
}