export const VIEW_ALL_CART_ITEMS = 'VIEW_ALL_CART_ITEMS'

export const DELETE_CART_ITEM = 'DELETE_CART_ITEM'

export const GET_CLINICS = 'GET_CLINICS'

export const SET_CART_COUNT = 'SET_CART_COUNT'

export const CLEAR_CLINICS_DATA = 'CLEAR_CLINICS_DATA'

