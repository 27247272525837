import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_PERSONAL_WEBSITE_CONFIGS, GET_WEBSITE_DETAILS } from "../../constants";


//1 date-api
const GET_PERSONAL_WEBSITE_CONFIGS_QUERY = gql`
query FrontGetPersonalWebsiteConfigs($websiteSlug: String) {
    frontGetPersonalWebsiteConfigs(websiteSlug: $websiteSlug) {
      success
      message
      data {
        first_name
        last_name
        business_name
        website_url_slug
        headline
        about
        business_contact
        business_email
        business_hours_display
        primary_color
        secondary_color
        tertiary_color
        terms_and_conditions
        current_step
        facebook
        instagram
        whatsapp
        logo_image_url
        cover_photo_image_url
      }
    }
  }
`

export const GetPersonalWebiteConfigsAction = () => {
    const dispatch = useDispatch()
    const [getPersonalWebiteConfigsResponseFunction, getPersonalWebiteConfigsReponseData] = useLazyQuery(
        GET_PERSONAL_WEBSITE_CONFIGS_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getPersonalWebiteConfigs: responseData }) => {
                console.log("responseData", responseData, "data")
                if (responseData?.success)
                    dispatch({
                        type: GET_PERSONAL_WEBSITE_CONFIGS,
                        payload: responseData
                    })
            }
        }
    )
    //this will be called from components
    const getPersonalWebiteConfigsResponseData = getPersonalWebiteConfigsReponseData //variable

    const initGetWebsiteConfigsResponseFunction = (data) => {

        getPersonalWebiteConfigsResponseFunction({
            variables: data
        })
    }
    return { initGetWebsiteConfigsResponseFunction, getPersonalWebiteConfigsResponseData }
}

