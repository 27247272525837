import { CLEAR_DATE_SLOTS, CLEAR_TIME_SLOTS, GET_DATE_SLOTS, GET_TIME_SLOTS, GET_TOTAL_AMOUNT } from "../../constants/treatment/DatetTimeslots"

const initialState = {
    dateSlots: [],
    timeslots: [],
    totalAmount: null,
    totalTimeSlotsCount: 0,
    loadedTimeSlotsCount: 0,
    timeSlotsPage: 0,
}

export const GetDateTimeSlotsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATE_SLOTS:
            let tempDate = state?.dateSlots.concat(action.payload?.data?.items)
            return {
                ...state,
                dateSlots: tempDate
            }
        case GET_TIME_SLOTS:
            // let tempTime = state?.timeslots.concat(action.payload?.data?.items)
            // let tempTime = action.payload?.data?.items
            const temp = (state?.timeslots).concat(action.payload?.data?.items)
            return {
                ...state,
                timeslots: temp,
                totalTimeSlotsCount: action.payload?.data?.total,
                timeSlotsPage: state.timeSlotsPage + 1,
                loadedTimeSlotsCount: temp.length
            }
        case CLEAR_TIME_SLOTS:
            return {
                ...state,
                timeslots: [],
                totalTimeSlotsCount: 0,
                timeSlotsPage: 0,
                loadedTimeSlotsCount: 0
            }

        case CLEAR_DATE_SLOTS:
            return {
                ...state,
                dateSlots: [],
            }

        case GET_TOTAL_AMOUNT:
            return {
                ...state,
                totalAmount: action.payload?.data?.payment
            }
        default:
            return state

    }
}