import React, { useState } from "react";
import "./auth-carousel.scss";
import dummyClient from "../../../assets/images/modal/authImage.jpg";

import { Carousel } from "react-bootstrap";

const AuthCarousel = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
        <div className="carsausel-customdiv">
            <Carousel
                indicators={false}
                activeIndex={index}
                onSelect={handleSelect}
                prevIcon={null}
                nextIcon={null}
                className="auth-carousel"
                style={{ padding: 0 }}
                
            >
                {[...Array(4)].map((item, index) => {
                    return (
                        <Carousel.Item>
                            <div className="auth-carousel-main-div">
                                <div className="bg-image">
                                    <div
                                        className="image"
                                        style={{ backgroundImage: `url(${dummyClient})` }}
                                    ></div>
                                    <div className="image-upper-content">
                                        <p className="about-text pe-3">
                                            “ The big secret in life is that there is no big secret.
                                            Whatever your goal, you can get there if you're willing to
                                            work.”
                                        </p>
                                        <span className="author">
                                            - By Oprah Winfrey
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        </div>
        </>
    );
};

export default AuthCarousel;
