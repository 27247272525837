import React, { useState } from "react";
import "./feed-back-thanks.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/close_icon.svg";
import { ReactComponent as FeedSuccess } from "../../../assets/images/icons/success.svg";
import StepButton from "../../common/StepButton";
import Modal from "react-modal";
import useWindowDimensions from "../../../utils/useWindowDimensions";

const FeedBackThanks = ({ isOpen, setIsOpen }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { screenWidth } = useWindowDimensions();


  return (
    <>
      {screenWidth < 768
        ?
        <Modal
          isOpen={isOpen}
          style={{
            overlay: {
              padding: '20px',
              background: "rgba(0, 0, 0, 0.5)"
            },
            content: {
              borderRadius: "30px",
              height: "fit-content",
              width: "90%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(0%, 0%)"
            },
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                width: "100%",
              }}
            >
              <CloseIcon
                className="cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            </div>
            <div className="feedback-popup-container mt-5">
              <FeedSuccess />
              <h1> Thanks a Bunch</h1>
              <p> Your input drives our improvements at Faces!</p>



              <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                <StepButton blue={true} label={"Done"} onClick={() => setIsOpen(false)} />
              </div>
            </div>
          </>
        </Modal>
        :
        <Modal
          isOpen={isOpen}
          style={{
            overlay: {
              padding: '20px',
              background: "rgba(0, 0, 0, 0.5)"
            },
            content: {
              borderRadius: "30px",
              height: "fit-content",
              width: "40%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: screenWidth < 576 ? "translate(0%, 0%)" : "translate(-50%, -50%)"
            },
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                width: "100%",
              }}
            >
              <CloseIcon
                className="cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            </div>
            <div className="feedback-thanks-popup-container mt-5">
              <FeedSuccess />
              <h1> Thanks a Bunch</h1>
              <p> Your input drives our improvements at Faces!</p>



              <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                <StepButton blue={true} label={"Done"} onClick={() => setIsOpen(false)} />
              </div>
            </div>
          </>
        </Modal>
      }

    </>
  );
};

export default FeedBackThanks;
