import React from 'react'
import Modal from 'react-modal'
import './cancle-modal.scss'
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close_icon.svg'
import StepButton from '../../common/StepButton'
import { useLocation, useNavigate, useParams } from 'react-router'
import useWindowDimensions from '../../../utils/useWindowDimensions'


const CancleModal = ({ isOpen, setIsOpen, bookingId }) => {
  const { slug, status } = useParams()

  const { screenWidth } = useWindowDimensions()
  const navigate = useNavigate()
  const location = useLocation()
  const data = location.state
  const handleCancleBooking = () => {
    setIsOpen(false)
    navigate(`/treatment-details/${slug}/${status}`, { state: data })
  }
  return (
    <>
      {
        screenWidth < 768
          ?
          <Modal className={"cancel-modal"}
            isOpen={isOpen}
            style={{
              overlay: {
                background: "rgba(0, 0, 0, 0.5)"
              },
              content: {
                borderRadius: "30px",
                height: "fit-content",
                width: "fit-content",
                position: "absolute",
                transform: "translate(0%, 50%)"
              }
            }}
          >
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                <CloseIcon className="cursor-pointer" onClick={() => { setIsOpen(false) }} />
              </div>
              <div className='cancle-popup-container mt-5'>
                <h1>Are you sure?</h1>
                <p>Do you really want to bin this ID? Once it's gone, it's gone for good!</p>
                <StepButton red={true} label={"Cancel"} onClick={handleCancleBooking} isLoading={false} />
              </div>
            </>
          </Modal>
          :
          <Modal className={"cancel-modal"}
            isOpen={isOpen}
            style={{
              overlay: {
                background: "rgba(0, 0, 0, 0.5)"
              },
              content: {
                borderRadius: "30px",
                // height: "30%",
                // width:"55%",
                position: "absolute",
                top: "50%",
                left: "50% ",
                transform: "translate(-50%, -50%)",
                overflow: "hidden"
              }
            }}
          >
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                <CloseIcon className="cursor-pointer" onClick={() => { setIsOpen(false) }} />
              </div>
              <div className='cancle-popup-container mt-2'>
                <h1>Are you sure?</h1>
                <p>Do you really want to bin this ID? Once it's gone, it's gone for good!</p>
                <StepButton red={true} label={"Cancel"} onClick={handleCancleBooking} />
              </div>
            </>
          </Modal>

      }

    </>
  )
}

export default CancleModal