import { COURSE_BOOKING_DETAILS, } from "../../constants"

const initialState = {
    courseBookingDetails: null
}

export const BookingCourseDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case COURSE_BOOKING_DETAILS:
            let courseBookingDetailsState = state.courseBookingDetails, requestData = action.requestData, payloadData = action.payload;
            if (requestData.generatePaymentIntent) {
                courseBookingDetailsState['data']['intentId'] = payloadData['data']['intentId'];
            }
            else {
                courseBookingDetailsState = payloadData;
            }
            return {
                ...state,
                courseBookingDetails: { ...courseBookingDetailsState }
            }
        default:
            return state
    }

}