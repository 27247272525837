import { gql, useLazyQuery, useMutation } from "@apollo/client";
// import { toast } from "react-toastify";
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux'
import { GET_BLOGS, SET_CART_COUNT } from "../../constants";


// ==========================GET WORDPRESS BLOGS==========================

// const GET_WORDPRESS_BLOGS_QUERY = gql`
// query GetWordpressBlogs {
//     getWordpressBlogs {
//       success
//       message
//       data {
//         posts {
//           title
//           category
//           type
//           author
//           postDate
//           url
//           image_url
//         }
//         total
//       }
//     }
//   }
// `;

// export const GetWordpressBlogsAction = () => {
//     const dispatch = useDispatch();
//     const [
//         GetWordpressBlogsResponseFunction,
//         getWordpressBlogsResponseData,
//     ] = useLazyQuery(GET_WORDPRESS_BLOGS_QUERY, {
//         fetchPolicy: "cache-and-network",
//         nextFetchPolicy: "cache-and-network",
//         onCompleted: ({ getWordpressBlogs: responseData }) => {
//             if (responseData.success) {
//                 dispatch({
//                     type: GET_BLOGS,
//                     payload: responseData
//                 })
//                 // toast.success(responseData?.message)
//             } else {
//                 // toast.error(responseData?.message)
//             }
//         },
//     });
//     //this will be called from components
//     const getWordpressBlogsQueryData = getWordpressBlogsResponseData; //variable

//     const initGetWordpressBlogsData = (data) => {
//         GetWordpressBlogsResponseFunction({
//             variables: data,
//         });
//     };

//     return { getWordpressBlogsQueryData, initGetWordpressBlogsData };
// };

// ==============================================================================


// ==========================GET REVIEW LIST==========================

const GET_REVIEW_LIST_QUERY = gql`
query GetReviewList($reviewType: String!) {
    getReviewList(reviewType: $reviewType) {
      success
      message
      data {
        reviews {
          userName
          reviewDate
          reviewFrom
          userRating
          userReview
          userImage
        }
        total_reviews
        average_rating
      }
    }
  }
`;

export const GetReviewListAction = () => {
    const dispatch = useDispatch();
    const [
        GetReviewListResponseFunction,
        getReviewListResponseData,
    ] = useLazyQuery(GET_REVIEW_LIST_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getReviewList: responseData }) => {
            if (responseData.success) {
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getReviewListQueryData = getReviewListResponseData; //variable

    const initGetReviewListData = (data) => {
        GetReviewListResponseFunction({
            variables: data,
        });
    };

    return { getReviewListQueryData, initGetReviewListData };
};

// ==============================================================================


// ==========================GET CART ITEMS COUNT==========================

const GET_CART_ITEMS_COUNT_QUERY = gql`
query GetCartItemsCount($sessionId: String) {
    getCartItemsCount(session_id: $sessionId) {
      success
      message
      data {
        courseCount
        treatmentCount
        totalCount
      }
    }
  }
`;

export const GetCartItemsCountAction = () => {
    const dispatch = useDispatch();
    const [
        GetCartItemsCountResponseFunction,
        getCartItemsCountResponseData,
    ] = useLazyQuery(GET_CART_ITEMS_COUNT_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getCartItemsCount: responseData }) => {
            if (responseData.success) {
                dispatch({
                    type: SET_CART_COUNT,
                    payload: responseData?.data,
                })
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getCartItemsCountQueryData = getCartItemsCountResponseData; //variable

    const initGetCartItemsCountData = (data) => {
        GetCartItemsCountResponseFunction({
            variables: data,
        });
    };

    return { getCartItemsCountQueryData, initGetCartItemsCountData };
};

// ==============================================================================


// ==========================CONTACT US==========================

const CONTACT_US_QUERY = gql`
query ContactUs($phone_number: String!, $email: String!, $message: String!, $name: String) {
    contactUs(phone_number: $phone_number, email: $email, message: $message, name: $name) {
      success
      message
    }
  }
`;

export const ContactUsAction = () => {
    const [
        ContactUsResponseFunction,
        contactUsResponseData,
    ] = useLazyQuery(CONTACT_US_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ contactUs: responseData }) => {
            if (responseData.success) {
                toast.success(responseData?.message)
            } else {
                toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const contactUsQueryData = contactUsResponseData; //variable

    const initContactUsData = (data) => {
        ContactUsResponseFunction({
            variables: data,
        });
    };

    return { contactUsQueryData, initContactUsData };
};

// ==============================================================================

// ==========================GET COUNT SUMMARY==========================

const GET_COUNT_SUMMARY_QUERY = gql`
query GetCountsSummary($type: Int!) {
    getCountsSummary(type: $type) {
      success
      message
      data {
        totalCount
        googleReviewCount
        weeklyCount
        followers
      }
    }
  }
`;

export const GetCountsSummaryAction = () => {
    const dispatch = useDispatch();
    const [
        GetCountsSummaryResponseFunction,
        getCountsSummaryResponseData,
    ] = useLazyQuery(GET_COUNT_SUMMARY_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getCountsSummary: responseData }) => {
            if (responseData.success) {
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getCountsSummaryQueryData = getCountsSummaryResponseData; //variable

    const initGetCountsSummaryData = (data) => {
        GetCountsSummaryResponseFunction({
            variables: data,
        });
    };

    return { getCountsSummaryQueryData, initGetCountsSummaryData };
};

// ==============================================================================