import React, { useEffect, useRef, useState } from "react";
import "./landingpage.scss";
import landingImage from "../../assets/images/landing-page/landingpage.svg";
import skynewsimage from "../../assets/images/landing-page/skynews.svg";
import { Rating } from "react-simple-star-rating";
import googleLogo from "../../assets/images/landing-page/google.svg";
import facesLogo from "../../assets/images/landing-page/Faces_logo.png";
import insyncLogo from "../../assets/images/landing-page/insyncLogo.png"
import insyncIcon from "../../assets/images/landing-page/insyncIcon.png"
import Ratingcard from "../ratingcard/Ratingcard";
import Slider from "react-slick";
import { useNavigate } from "react-router";
// import { clinicHome } from "../../Routes";
import { GetReviewListAction } from "../../redux/actions/home-page/Academy";
import starIcon from "../../assets/images/icons/star.svg"
import { useSelector } from "react-redux";




const LandingPage = () => {
    const navigate = useNavigate()
    const { getReviewListQueryData: google, initGetReviewListData } = GetReviewListAction()
    const { getReviewListQueryData: faces, initGetReviewListData: initFacesData } = GetReviewListAction()

    const [isInfinite, setIsInfinite] = useState(true);
    const [isInfinite1, setIsInfinite1] = useState(true);

    useEffect(() => {
        initGetReviewListData({
            reviewType: "google"
        });
        initFacesData({
            reviewType: "faces"
        });
    }, []);

    const websiteApiData = useSelector(
        (state) => state.WebsiteDetailsReducer.websiteApiData
    );

    console.log("websiteApiData", websiteApiData)

    useEffect(() => {
        if (faces?.data?.getReviewList?.data?.reviews?.length > 2) {
            setIsInfinite(true);
        } else {
            setIsInfinite(false);
        }

        if (google?.data?.getReviewList?.data?.reviews?.length > 2) {
            setIsInfinite1(true);
        } else {
            setIsInfinite1(false);
        }
    }, [faces?.data?.getReviewList?.data?.reviews, google?.data?.getReviewList?.data?.reviews]);


    // ==========Faces reviews================
    var settings = {
        autoplaySpeed: 4000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: isInfinite,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: isInfinite,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    className: "center",
                    infinite: isInfinite
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: isInfinite,
                    autoplay: false
                }
            }
        ]
    };

    // ==========Googele reviews================
    var settings1 = {
        autoplaySpeed: 4000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: isInfinite1,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: isInfinite1,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    className: "center",
                    infinite: isInfinite1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: isInfinite1
                }
            }
        ]
    };

    const [maxHeight, setMaxHeight] = useState(0);
    const cardRefs = useRef([]);
    
    useEffect(() => {
        const heights = cardRefs.current.map(card => card.offsetHeight);
        setMaxHeight(Math.max(...heights));
    }, [faces]);
    return (
        <>
            <div className="landing-page-div">

                {/* <div className="image-container">
                    <div className="bg-image">
                        <div className="image">

                        </div>
                    </div>

                    <div className="image-div-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="title">
                                        <h1>
                                            Welcome to <br />
                                            Kanvas Medical Group
                                        </h1>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-12 col-sm-6">
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="landing-button">
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="landing-button">


                        </div>
                        <div className="skynews-div mt-4">
                            <span>‘Innovation in aesthetics’</span>
                            <div className="sky-news-image ">
                                <img src={skynewsimage} alt="skynewsimage" />
                            </div>
                        </div>
                    </div>

                </div> */}
                {/* =============================FACES REVIEWS============================= */}
                {
                    faces?.data?.getReviewList?.data?.reviews?.length > 0 &&
                    <div className="landing-footer-div review-hr">
                        <div className="container">
                            <div className="row align-items-center custom-review-box">
                                <div></div>
                                <div className="col-3 col-md-3 box-1">
                                    <div className="google-image-div">
                                        <div className="d-flex flex-column align-items-start">
                                            <div className="google-image">
                                                <img src={websiteApiData?.created_from === "faces" ? facesLogo : insyncLogo} alt="facesLogo" />
                                            </div>
                                            <Rating
                                                className="ratings-div"
                                                allowFraction
                                                size={26}
                                                initialValue={faces?.data?.getReviewList?.data?.average_rating}
                                                readonly={true}
                                            />
                                            <span className="display-total-reviews">
                                                <div className="star">
                                                    <img src={starIcon} alt="Star Icon" />
                                                </div>
                                                <span className="rating-number">{faces?.data?.getReviewList?.data?.average_rating}</span>
                                                <span className="rating-people">({faces?.data.getReviewList.data.total_reviews})</span>
                                            </span>
                                            {
                                                faces?.data.getReviewList.data.total_reviews > 1000 ?
                                                    <span>1k+ Reviews</span>
                                                    :
                                                    <span>{faces?.data.getReviewList.data.total_reviews} Reviews</span>
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="col-9 col-md-9 mt-4 mt-sm-0 box-2">
                                    <div className="row">
                                        <div className="ratingCardReview">
                                            <Slider {...settings}>
                                                {faces?.data?.getReviewList?.data?.reviews?.length > 0 && faces?.data?.getReviewList?.data?.reviews?.map((item, index) => (
                                                    <div className="col-12 col-md-3 slider-box" key={index}  
                                                    ref={el => cardRefs.current[index] = el}>
                                                        <Ratingcard listItem={item} type={"faces"} style={{height: maxHeight }} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* =============================GOOGLE REVIEWS============================= */}

                {/* {
                    google?.data?.getReviewList?.data?.reviews?.length > 0 &&
                    <div className="landing-footer-div px-0 px-md-3 px-sm-5 py-5">
                        <div className="container-xxl">
                            <div className="row align-items-center">
                                <div></div>
                                <div className="col-12 col-md-3">
                                    <div className="google-image-div">
                                        <div className="d-flex flex-column align-items-start">
                                            <div className="google-image">
                                                <img src={googleLogo} alt="google" />
                                            </div>
                                            <Rating
                                                className="ratings-div"
                                                allowFraction
                                                size={26}
                                                initialValue={google?.data?.getReviewList?.data?.average_rating}
                                                readonly={true}
                                            />
                                            {
                                                google?.data.getReviewList.data.total_reviews > 1000 ?
                                                    <span>1k+ Reviews</span>
                                                    :
                                                    <span>{google?.data.getReviewList.data.total_reviews} Reviews</span>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-9 mt-4 mt-sm-0">
                                    <div className="row">
                                        <div className="">
                                            <Slider {...settings1}>
                                                {google?.data?.getReviewList?.data?.reviews?.length > 0 && google?.data?.getReviewList?.data?.reviews?.map((item, index) => (
                                                    <div className="col-12 col-md-3 px-3" key={index}>
                                                        <Ratingcard listItem={item} type={"google"} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                } */}
            </div>

        </>
    );
};

export default LandingPage;
