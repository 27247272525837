import LoadingOverlay from 'react-loading-overlay';
import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux'
import './loader.scss'
import { InfinitySpin, MagnifyingGlass, ThreeCircles } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

LoadingOverlay.propTypes = undefined


export default function Loader({ isActive, ...props }) {

  const websiteApiData = useSelector((state) => state.WebsiteDetailsReducer.websiteApiData);


  // const loadingCount = useSelector((state) => state.loaderReducer.loadingCount);
  // const isActive = loadingCount > 0 ? true : false;

  // const searchLoader = useSelector((state) => state.loaderReducer.searchLoader);

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, "400")

  useEffect(() => {
    if(websiteApiData){
      if(websiteApiData?.is_active){
        setLoading(false);  
      }
      setLoading(false);
    }else{
    setLoading(true);
  }
  }, [websiteApiData, loading]);

  useEffect(() => {
    if (isActive || loading)
      document.body.classList.add('loader-overflow-none')
    else
      document.body.classList.remove('loader-overflow-none')
  }, [isActive, loading])

  return (
    <div className='loader-container'>

      <LoadingOverlay
        active={isActive || loading}
        className='loading-overlay'
      >
        {(isActive || loading) && <div className='infinity-spin-loader'>
          <InfinitySpin
            width='200'
            color={'#fff'}
          />
        </div>}

        {props.children}
      </LoadingOverlay>

    </div>
  );

}